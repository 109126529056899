<template>
  <b-table
    striped
    hover
    responsive
    small
    show-empty
    class="position-relative items-center"
    :per-page="perPage"
    :items="items"
    :fields="fields"
  >
    <template #cell(index)="data">
      {{ perPage * (currentPage - 1) + (data.index + 1) }}
    </template>
    <template #empty="scope">
      <div class="p-2 text-center">
        <img
          src="/box.png"
          alt="empty"
          height="40px"
        >
        {{
          scope.emptyFiltered ? "No matching records found" : "ไม่พบข้อมูล"
        }}
      </div>
    </template>
    <template #cell(detail)="data">
      <div class="text-nowrap">
        <b-button
          title="รายละเอียด"
          variant="gradient-info"
          size="sm"
          @click="$router.push({ name: 'Deposit-Withdraw', params: { id: data.item.username } })"
        >
          <i class="fas fa-address-card" />
        </b-button>
      </div>
    </template>
    <template #cell(main_wallet)="data">
      <span class="text-warning">{{ data.item.main_wallet.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
    </template>
    <template #cell(total_deposit)="data">
      <span class="text-success">{{ data.item.total_deposit.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) }}</span>
    </template>
    <template #cell(withdraw)="data">
      <span class="text-danger">{{ data.item.withdraw.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) }}</span>
    </template>
    <template #cell(bonus)="data">
      <span class="text-primary">{{ data.item.bonus.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
    </template>
    <template #cell(tel)="data">
      {{ data.item.tel }}
    </template>

    <template #cell(name)="data">
      {{ data.item.name }}
      {{ data.item.surname }}
    </template>

    <template #cell(user_pass)="data">
      <button
        :id="data"
        class="btn btn-sm btn-warning"
        @click="$emit('getCreditBalance', data.item.username)"
      >
        {{ data.item.username }}
      </button>
    </template>

    <template #cell(bankname)="data">
      <div class="text-center">
        <img
          :src="`/bankIcon/${data.item.bank_img}`"
          alt="bankIcon"
          height="25"
          width="25"
          class="rounded-circle"
        >
        <br>
        <small>{{ data.item.acc_no }}</small>
      </div>
    </template>

    <template #cell(actions)="data">
      <b-dropdown
        id="dropdown-3"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        text="Success"
        variant="success"
      >
        <template #button-content>
          <i class="far fa-edit mr-50" />
        </template>
        <b-dropdown-item
          @click="
            $router.push({
              name: 'history-turnover',
              params: { id: data.item.username },
            })
          "
        ><i class="far fa-file-spreadsheet mr-1 text-info" /> Turn Over</b-dropdown-item>
        <b-dropdown-item
          @click="revokeToken(data.item.username)"
        ><i class="far fa-sign-out mr-1 text-danger" /> ออกจากระบบ</b-dropdown-item>
        <b-dropdown-item
          v-if="role && role.role.filter(item => item.id === 'member')[0].permission_edit"
          @click="
            $router.push({
              name: 'Deposit-Withdraw',
              params: { id: data.item.username },
            })
          "
        ><i class="far fa-edit mr-1 text-warning" />แก้ไข</b-dropdown-item>
        <b-dropdown-item><i class="fas fa-trash mr-1 text-danger" /> ลบ
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>

<script>
import {
  BTable, VBToggle, VBTooltip, BButton, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment-timezone'

export default {
  components: {
    BTable, VBToggle, VBTooltip, BButton, BDropdown, BDropdownItem,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: ['items', 'perPage'],
  emits: ['sorted', 'getCreditBalance'],
  data: () => ({
    fields: [
      // { key: 'detail', label: 'รายละเอียด' },
      { key: 'main_wallet', label: 'ยอดเงิน', sortable: true },
      { key: 'tel', label: 'เบอร์โทร' },
      { key: 'name', label: 'ชื่อ-นามสกุล' },
      { key: 'deposit_count', label: 'ครั้งที่ฝาก', sortable: true },
      { key: 'total_deposit', label: 'ฝากรวม', sortable: true },
      {
        key: 'deplast_date',
        label: 'ฝากครั้งสุดท้ายเมื่อ',
        sortable: true,
        formatter: value => (value
          ? moment(value)
            .tz('Asia/Bangkok')
            .format('DD-MM-YYYY HH:mm')
          : ''),
      },
      { key: 'bonus', label: 'โบนัส' },
      { key: 'withdraw_count', label: 'ครั้งที่ถอน', sortable: true },
      { key: 'withdraw', label: 'ถอนรวม', sortable: true },
      { key: 'invited_by_name', label: 'ผู้แนะนำ', sortable: true },
      { key: 'bankname', label: 'ธนาคาร', thClass: 'text-center' },
      {
        key: 'created_at',
        label: 'สมัครเมื่อ',
        sortable: true,
        formatter: value => (value
          ? moment(value)
            .tz('Asia/Bangkok')
            .add('hours', 7)
            .format('DD-MM-YYYY HH:mm')
          : ''),
      },
      { key: 'user_pass', label: 'รีเซ็ตรหัสผ่าน' },
      // { key: 'actions', label: 'จัดการ' },
    ],
  }),
  computed: {
    role() {
      return this.$store.state.app.role
    },
  },
  methods: {
    onSortChanged(ctx) {
      const { sortBy, sortDesc } = ctx

      this.$emit('sorted', { sortBy, sortDesc })
    },
    async revokeToken(username) {
      try {
        const { data } = await this.$http.post(`/user/revokeToken/${username}`)

        this.$swal({
          icon: 'success',
          title: '<h3 style="color: #141414">ดำเนินการสำเร็จ</h3>',
          text: data.message,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
          text: error.response.data.message,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }
    },
  },
}

</script>
